import React from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Typography,
    Grid,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core'

import { formatUnixtimestamp } from '../utils/dateUtils'

import IndledendeVurderingView from './sections/readonlyviews/IndledendeVurderingView'
import ATilbagegangIBestandView from './sections/readonlyviews/A_TilbagegangIBestandView'
import BGeografiskUdbredelseView from './sections/readonlyviews/B_GeografiskUdbredelseView'
import CBestandsstorrelseView from './sections/readonlyviews/C_BestandsstorrelseView'
import DKvantitativAnalyseView from './sections/readonlyviews/D_KvantitativAnalyseView'
import EOpNedKategoriseringView from './sections/readonlyviews/E_OpNedKategoriseringView'
import FLevestedsAngivelseView from './sections/readonlyviews/F_LevestedsAngivelseView'
import GTruslerView from './sections/readonlyviews/G_TruslerView'
import SupplerendeOplysningerView from './sections/readonlyviews/SupplerendeOplysningerView'
import CategoryChangeView from './sections/readonlyviews/X_CategoryChangeView'

import { sections, SectionType } from '../service/assessmentSections'


import { setTitle } from '../redux/actions/uiActions'
import { RedlistStore } from '../redux/reducers'
import { CommentObject, CommentType } from '../redux/reducers/editAssessmentRed'
import { UserType } from '../redux/reducers/appReducer'

interface CommentProps {
    comment: CommentType
}

const Comment = (props: CommentProps ) => {
    const { comment } = props
    return (
        <Grid item xs={12} style={{ backgroundColor: '#eee', padding: 8, margin: 4, borderRadius: 2 }}>
                    <Typography style={{ display: 'block', marginRight: 8, color: '#777' }} variant='body1'>{formatUnixtimestamp(comment.created)} - {comment.user}</Typography>
                    <Typography style={{ display: 'inline-block' }} variant='body1'>{comment.comment}</Typography>                   
  
        </Grid>
    )
}

interface CommentsListProps {
    comments: CommentObject;
    user: UserType | null;
    section: SectionType | { section: string }
}

const Comments = (props : CommentsListProps) => {
    const { comments, section: sectionObject } = props
    const section = sectionObject.section || "general"
    console.log({comments, section})
    if (!comments) {
        console.log({comments})
        return (<></>)
    }
    const _comments = comments[section] 
    if (!_comments) {
        return (<></>)
    }
    _comments.sort((a, b) => b.created - a.created)

    return (
        <Grid item xs={12}>
            <Typography variant='subtitle1'>Kommentarer</Typography>
            {_comments.map(c => <Comment key={c.id} comment={c} />)}            
        </Grid>
    )
}


export interface AssessmentReadOnlyPageProps {
    title: string;
}

const AssessmentReadOnlyPage = (props : AssessmentReadOnlyPageProps) => {
    const { title } = props
    const dispatch = useDispatch()
    const { threatDefinition } = useSelector((state : RedlistStore) => state.ekspertEntities)
    const user = useSelector((state : RedlistStore) => state.app.user)
   // const { id } = useParams()
    const {
        assessmentId,
        assessmentCriteria,
        categoryChangeReasons,
        speciesInformation,
        category,
        threat,
        comments : _comments
    } = useSelector((state : RedlistStore) => state.editAssessment)
    const {
        editLevested : levested,
        editSupplerendeOplysninger : supplerendeOplysninger,                
    } = useSelector((state : RedlistStore) => state)
    console.log({assessmentCriteria})
    //const { assessmentCriteria } = assessment || {}
    //const categoryChangeReasons = assessment?.categoryChangeReasons || []
    const comments = _comments as CommentObject
    
    useEffect(() => {        
        if (assessmentId && speciesInformation) {
            const _category = category || ''            
            dispatch(setTitle(title + ' - ' + speciesInformation.scientificName + ' (' + _category + ') Read Only'))
        }
    }, [ dispatch, title, assessmentId, category, speciesInformation])

    const {
        preliminary,
        reduction,
        geography,
        population,
        quantitativeAnalysis,
        adjustment
    } = assessmentCriteria || {}
  
    
    console.log({categoryChangeReasons, supplerendeOplysninger, threat})

    return (
        <div>
            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Indledende vurdering</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <IndledendeVurderingView showNull preliminary={preliminary} />
                        <Comments user={user} comments={comments} section={sections.preliminary} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Tilbagegang i bestand</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <ATilbagegangIBestandView showNull reduction={reduction} />
                        <Comments user={user} comments={comments} section={sections.reduction} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Geografisk udbredelse</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <BGeografiskUdbredelseView showNull geography={geography} />
                        <Comments user={user} comments={comments} section={sections.geography} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Bestandsstørrelse</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <CBestandsstorrelseView showNull population={population} />
                        <Comments user={user} comments={comments} section={sections.population} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Kvantitativ analyse</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <DKvantitativAnalyseView showNull quantitativeAnalysis={quantitativeAnalysis} />
                        <Comments user={user} comments={comments} section={sections.quantitativeAnalysis} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Op/ned-kategorisering</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <EOpNedKategoriseringView showNull adjustment={adjustment} />
                        <Comments user={user} comments={comments} section={sections.adjustment} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Levestedsangivelse</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <FLevestedsAngivelseView showNull levested={levested} />
                        <Comments user={user} comments={comments} section={sections.levesteder} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Trusler</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <GTruslerView showNull threat={threat} threatDefinition={threatDefinition} />
                        <Comments user={user} comments={comments} section={sections.trusler} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Supplerende oplysninger</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <SupplerendeOplysningerView showNull supplerendeOplysninger={supplerendeOplysninger} />
                        <Comments user={user} comments={comments} section={sections.supplerendeOplysninger} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Evt. årsag til kategoriskift siden 2010</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <CategoryChangeView categoryChanges={categoryChangeReasons} vurderingsrundeId={2010}/>
                        <Comments user={user} comments={comments} section={sections.kategoriskift2010} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary>
                    <Typography variant='h6'>Evt. årsag til kategoriskift siden 2019</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <CategoryChangeView categoryChanges={categoryChangeReasons} vurderingsrundeId={2019}/>
                        <Comments user={user} comments={comments} section={sections.kategoriskift2019}  />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Paper style={{ paddingLeft: 16, paddingTop: 16 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Generelle kommentarer</Typography>
                    </Grid>
                    <Comments user={user} comments={comments} section={{ section: 'general' }} />                    
                </Grid>
            </Paper>
        </div>
    )
}

export default AssessmentReadOnlyPage