import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Grid } from "@material-ui/core";
import TextAreaReference from "../components/TextAreaReference";
import TextFieldWithHelp from "../components/TextfieldWithHelp";
import BooleanRadioGroup from "../components/BooleanRadioGroup";
import { oddRowStyle } from "../components/styles";
import {
  AoeHelpElement,
  FragmenteretHelpElement,
  LokaliteterHelpElement,
  VedvarendeTilbagegangHelpElement,
  EkstremeUdsvingHelpElement,
} from "./help/geographyHelp";
import {
  updateGeography,
  saveSection,
} from "../../redux/editAssessment/editAssessmentActions";
import Footer from "../components/Footer";
import { extractPropertyValidationResult } from "./validators/sectionValidators";

import GlobalErrorMessage from "../components/GlobalErrorMessage";
import GeographyMapComponent from '../geography/GeographyMapComponent'

import { geographyQuestions } from "./questions";
import { RedlistStore } from "../../redux/reducers";
import { GeographyCriteria } from "../../redux/reducers/editAssessmentRed";

export interface B_GeografiskUdbredelseProps {
    referenceArt : any;
    onNext : any;
    onClose : any;
}

const B_GeografiskUdbredelse = (props : B_GeografiskUdbredelseProps) => {
  const dispatch = useDispatch();
  const assessmentId = useSelector(
    (state: RedlistStore) => state.editAssessment.assessmentId
  );
  const currentCriteria = useSelector(
    (state: RedlistStore) => state.editAssessment.assessmentCriteria
  );
  const currentGeography : GeographyCriteria = currentCriteria ? currentCriteria.geography : {
    extentOfTheSpeciesIsFragmented: null,
    extremeFluctuations: null,
    continuedReduction: null
  };
  const { referenceArt, onNext, onClose } = props;
  const referenceGeography =
    referenceArt &&
    referenceArt.assessmentCriteria &&
    referenceArt.assessmentCriteria.geography;

  const extentOfOccurrence = currentGeography.extentOfOccurrence || "";
  const areaOfOccupancy = currentGeography.areaOfOccupancy || "";
  const extentLimitedToNoOfLocations =
    currentGeography.extentLimitedToNoOfLocations || "";
  const geographicExtentRemark = currentGeography.geographicExtentRemark || "";
  const {
    extentOfTheSpeciesIsFragmented,
    continuedReduction,
    extremeFluctuations,
  } = currentGeography;

  const validationResult = useSelector(
    (state: RedlistStore) => state.editAssessment.criteriaValidationResult?.geography
  );

  if (assessmentId == null) {
    return <></>
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Geografisk udbredelse</Typography>
        <GlobalErrorMessage validationResult={validationResult} />
      </Grid>
      <Grid item xs={12}>
        <GeographyMapComponent />
      </Grid>
      <Grid item xs={12}>
        <TextFieldWithHelp
          type="number"
          validation={extractPropertyValidationResult(
            validationResult,
            "extentOfOccurrence"
          )}
          referenceValue={
            referenceGeography && referenceGeography.extentOfOccurrence
          }
          value={`${extentOfOccurrence}`}
          onChange={(value : string) =>
            {
              console.log({value, parsed: parseFloat(value), action: updateGeography("extentOfOccurrence", parseFloat(value))})
              dispatch(updateGeography("extentOfOccurrence", parseFloat(value)))
            }
          }
          title={geographyQuestions.extentOfOccurrence.title}
          label={geographyQuestions.extentOfOccurrence.label}
          help={() => <AoeHelpElement />}
        />
      </Grid>
      <Grid style={oddRowStyle} item xs={12}>
        <TextFieldWithHelp
          type="number"
          validation={extractPropertyValidationResult(
            validationResult,
            "areaOfOccupancy"
          )}
          referenceValue={
            referenceGeography && referenceGeography.areaOfOccupancy
          }
          value={`${areaOfOccupancy}`}
          onChange={(value : string) =>
            dispatch(updateGeography("areaOfOccupancy", parseFloat(value)))
          }
          title={geographyQuestions.areaOfOccupancy.title}
          label={geographyQuestions.areaOfOccupancy.label}
          help={() => <AoeHelpElement />}
        />
      </Grid>
      <Grid item xs={12}>
        <BooleanRadioGroup
          help={() => <FragmenteretHelpElement />}
          ghost={
            referenceGeography &&
            referenceGeography.extentOfTheSpeciesIsFragmented
          }
          value={extentOfTheSpeciesIsFragmented}
          onChange={(value : (boolean | null)) =>
            dispatch(updateGeography("extentOfTheSpeciesIsFragmented", value))
          }
          label={geographyQuestions.extentOfTheSpeciesIsFragmented.label}
        />
      </Grid>
      <Grid style={oddRowStyle} item xs={12}>
        <TextFieldWithHelp
          type="number"
          validation={extractPropertyValidationResult(
            validationResult,
            "extentLimitedToNoOfLocations"
          )}
          referenceValue={
            referenceGeography &&
            referenceGeography.extentLimitedToNoOfLocations
          }
          value={`${extentLimitedToNoOfLocations}`}
          onChange={(value : string) =>
            dispatch(updateGeography("extentLimitedToNoOfLocations",  parseInt(value)))
          }
          title={geographyQuestions.extentLimitedToNoOfLocations.title}
          label={geographyQuestions.extentLimitedToNoOfLocations.label}
          help={() => <LokaliteterHelpElement />}
        />
      </Grid>
      <Grid item xs={12}>
        <BooleanRadioGroup
          help={() => <VedvarendeTilbagegangHelpElement />}
          ghost={referenceGeography && referenceGeography.continuedReduction}
          value={continuedReduction}
          onChange={(value : (boolean | null)) =>
            dispatch(updateGeography("continuedReduction", value))
          }
          label={geographyQuestions.continuedReduction.label}
        />
      </Grid>
      <Grid style={oddRowStyle} item xs={12}>
        <BooleanRadioGroup
          help={() => <EkstremeUdsvingHelpElement />}
          ghost={referenceGeography && referenceGeography.extremeFluctuations}
          value={extremeFluctuations}
          onChange={(value : (boolean | null)) =>
            dispatch(updateGeography("extremeFluctuations", value))
          }
          label={geographyQuestions.extremeFluctuations.label}
        />
      </Grid>
      <TextAreaReference
        validation={extractPropertyValidationResult(
          validationResult,
          "geographicExtentRemark"
        )}
        title={geographyQuestions.geographicExtentRemark.title}
        value={geographicExtentRemark}
        onChange={(value : string) =>
          dispatch(updateGeography("geographicExtentRemark", value))
        }
        referenceValue={
          referenceGeography && referenceGeography.geographicExtentRemark
        }
        referenceTitle="Referenceart"
      />
      <Footer
        onNext={() => {
          dispatch(saveSection("geography", assessmentId, currentGeography));
          onNext();
        }}
        onClose={() => onClose()}
      />{" "}
    </Grid>
  );
};

export default B_GeografiskUdbredelse;
