import { FixMeLater } from "../../components/GlobalErrorMessage"

export interface PropertyValidationResult {
  ok: boolean;
  message?: string;
}

export const extractPropertyValidationResult = (validationResult : FixMeLater, propertyName : string) : PropertyValidationResult => {
  if (!validationResult) {
    return { ok: true }
  }
  if (validationResult.ok) {
    return { ok: true }
  }
  if (validationResult.global) {
    return { ok: true }
  }
  if (!validationResult.properties) {
    return { ok: true }
  }
  const property = validationResult.properties[propertyName]
  if (!property) {
    return { ok: true }
  }
  return {
    ok: false,
    message: property.message,
  }
}

export const toInt = (value? : string | number | null) : number => {
  return parseInt(`${value}`)
}

export const toFloat = (value? : string | number | null) : number => {
  return parseFloat(`${value}`)
}

export const isNullOrEmpty = <E> (value : E, nullValue?: (E | string)) : boolean => {
  if (value === false || value === 0 || value === nullValue) {
    return false
  }
  return !value
}

export const nullOrNullValue = (value : any, nullValue : any) : boolean => isNullOrEmpty(value) || value === nullValue


export const isArrayNullOrEmpty = (value : any) => {
  if (!value || value.length === 0) {
    return true
  }
  return false
}

export const allEmpty = (...args : any[]) => args.reduce((p, c) => p && isNullOrEmpty(c), true)

export const allEmptyOrNullValue = (nullValue : (string | null), ...args : any[]) => args.reduce((p, c) => p && isNullOrEmpty(c, nullValue), true)

export const objectIsEmpty = (object : any) : boolean => {
  let empty = true
  Object.keys(object).forEach((k) => {
    if (!isNullOrEmpty(object[k])) {
      empty = false
    }
  })
  return empty
}

export function oneOf<E> (value : E, list : E[]) {
  return list.filter((e) => e === value).length > 0
}

export const validationResultFromProperties = (properties : Record<string, any>, empty : boolean) => {
  if (Object.keys(properties).length === 0) {
    return {
      ok: true,
      empty: empty,
    }
  } else {
    return {
      ok: false,
      empty,
      properties,
    }
  }
}
