import { floatInterval, integerInterval } from '../../../utils/validators'
import { isNullOrEmpty, objectIsEmpty, toFloat, toInt, validationResultFromProperties } from './sectionValidators'
import { preliminaryValuesAssessed } from '../1_IndledendeVurdering'
import { AssessmentCriteria } from '../../../redux/reducers/editAssessmentRed'
import { ValidationProperties } from './validationModels'

export const geographyValidator = (criteria : AssessmentCriteria) => {
  const { geography, preliminary } = criteria
  const empty = objectIsEmpty(geography)
  if (preliminary.preliminaryAssessment !== preliminaryValuesAssessed) {
    if (!empty) {
      // console.log('geo not empty', geography)
      return {
        ok: false,
        global: true,
        message:
          'Geografisk udbredelse må ikke udfyldes når der er angivet en indledende vurdering, der gør at arten ikke skal vurderes',
      }
    }
  }

  const {
    extentOfOccurrence,
    areaOfOccupancy,
    extentLimitedToNoOfLocations,
    extentOfTheSpeciesIsFragmented,
    geographicExtentRemark,
  } = geography

  console.log({extentOfOccurrence})

  if (isNullOrEmpty(extentOfOccurrence) && isNullOrEmpty(areaOfOccupancy) && !empty) {
    return {
      ok: false,
      global: true,
      message:
        'Der skal angives et udbredelsesareal eller et forekomstsareal når der er angivet værdier under geografisk udbredelse',
    }
  }

  /*
        Hvis man angiver 1 eller 2 lokaliteter under "geografisk udbredelse" 
        kan man ikke angive udbredelsesareal, for så er det ikke defineret.
    */
  if (!isNullOrEmpty(extentLimitedToNoOfLocations)) {
    const lokaliteter = toInt(extentLimitedToNoOfLocations)
    if (lokaliteter === 1) {
      if (!isNullOrEmpty(extentOfOccurrence)) {
        return {
          ok: false,
          global: true,
          message:
            'Hvis man angiver 1 lokalitet under "geografisk udbredelse" kan man ikke angive udbredelsesareal, for så er det ikke defineret.',
        }
      }
    }
    if (lokaliteter === 2) {
      if (!isNullOrEmpty(extentOfOccurrence)) {
        return {
          ok: true,
          global: true,
          warning: true,
          message:
            'Du har angivet 2 lokaliteter under "geografisk udbredelse" og et udbredelsesareal. Er du sikker på udberdelsesarealet er defineret?',
        }
      }
    }
    if (lokaliteter === 1) {
      if (extentOfTheSpeciesIsFragmented) {
        return {
          ok: false,
          global: true,
          message: 'Hvis man angiver 1 lokalitet kan man ikke angive "stærkt fragmenteret" så giver det ikke mening.',
        }
      }
    }
  }

  //Hvis man under "Geografisk udbredelse" angiver både forekomstareal og udbredelsesareal gives en advarsel og en "check manualen" hvis forekomstarealet er større end udbredelsesarealet; det kan noromalt ikke forekomme, men ikke helt udelukkes i meget særlige tilfælde,
  if (!isNullOrEmpty(areaOfOccupancy) && !isNullOrEmpty(extentOfOccurrence)) {
    const udbredelsesareal = toFloat(extentOfOccurrence)
    const forekomstareal = toFloat(areaOfOccupancy)
    // FIXME> Hvad skal der ske hvis udbredelsesarealet er større end forekomstarealet?
    if (forekomstareal > udbredelsesareal) {
      return {
        ok: true,
        global: true,
        warning: true,
        empty: false,
        message: 'Forekomstarealet er større end udbredelsesarealet: "check manualen"',
      }
    }
  }

  const properties : ValidationProperties = {}
  if (floatInterval(0.01, 200000, true)(extentOfOccurrence).ok === false) {
    properties['extentOfOccurrence'] = {
      message: 'Udbrelsesareal skal være et tal mellem 0.01 og 200000',
    }
  }
  if (floatInterval(0.01, 200000, true)(areaOfOccupancy).ok === false) {
    properties['areaOfOccupancy'] = {
      message: 'Forekomstsareal skal være et tal mellem 0.01 og 200000',
    }
  }
  const nolValidation = integerInterval(1, false, true)(extentLimitedToNoOfLocations)
  if (nolValidation.ok === false) {
    properties.extentLimitedToNoOfLocations = {
      message: nolValidation.message || "",
    }
  }

  if (!empty && !geographicExtentRemark) {
    properties.geographicExtentRemark = {
      message: 'Hvis der er udfyldt noget i denne sektion skal der være skrevet noget i dokumentationsfeltet',
    }
  }
  return validationResultFromProperties(properties, empty)
}
