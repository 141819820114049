import { AssessmentCriteria } from "../../../redux/reducers/editAssessmentRed";
import { Categories } from "../../../service/categoryEngine";
import { FixMeLater } from "../../components/GlobalErrorMessage";

import {
  isNullOrEmpty,
  objectIsEmpty,
  validationResultFromProperties,
} from "./sectionValidators";
import { ValidationProperties } from "./validationModels";

type RequiredValueType = "ok" | "value missing" | "not required";

const validate = <E> (required: boolean, value: E): RequiredValueType => {
  if (required) {
    if (isNullOrEmpty(value)) {
      return "value missing";
    }
  } else {
    if (!isNullOrEmpty(value)) {
      return "not required";
    }
  }
  return "ok";
};

export const globalValidator = (
  criteria: AssessmentCriteria,
  category: FixMeLater
) => {
  const global = criteria.global;
  const geography = criteria.geography;
  const empty = objectIsEmpty(global);
  const requirePossiblyRE = category && category.value === Categories.CR.value;

  const requireUseArterDKData = Boolean(
    geography.areaOfOccupancy || geography.extentOfOccurrence
  );


  const properties: ValidationProperties = {};

  const useArterDkData = global.useArterDkData === 'null' ? null : global.useArterDkData

  const useArterResult = validate(requireUseArterDKData, useArterDkData);
  console.log({requireUseArterDKData, useArterResult, useArterDkData: global.useArterDkData})
  if (useArterResult === "value missing") {
    properties["useArterDkData"] = {
      message: "Der skal angives om arter.dk anvendes",
    };
  } else if (useArterResult === "not required") {
    properties["useArterDkData"] = {
      message:
        "Der skal kun angives om arter.dk data anvendes hvis der er angivet et udbredelsesareal eller forekomstsareal",
    };
  }

  const possiblyREResult = validate(requirePossiblyRE, global.possiblyRE);
  if (possiblyREResult === "value missing") {
    properties["possiblyRE"] = {
      message: "Når en art er CR skal der udfyldes om arten muligvis er uddød",
    };
  } else if (possiblyREResult === "not required") {
    properties["possiblyRE"] = {
      message:
        "Når en art ikke er CR må der ikke udfyldes om arten muligvis er uddød",
    };
  }
  console.log({ properties, requirePossiblyRE, possiblyRE: global.possiblyRE });
  return validationResultFromProperties(properties, empty);
};
